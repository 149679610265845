import { template as template_306023be816f44219070a26beac76359 } from "@ember/template-compiler";
const SidebarSectionMessage = template_306023be816f44219070a26beac76359(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
